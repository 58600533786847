import * as API from "../lib/API";

import { Controller } from 'stimulus';

export default class UserNotificationsController extends Controller {
  static targets = ["toggle"];

  toggle() {
    let input = this.toggleTarget;
    let enableUrl = input.dataset.enableUrl;
    let disableUrl = input.dataset.disableUrl;
    let checked = input.checked;

    let url = checked ? enableUrl : disableUrl;
    API.post(url, {});
  }
}
