import _ from './shared/airbrake'
import './shared/base';

// Setup simtulus controllers
import { Application } from 'stimulus';
import SubmittableController from './controllers/submittable_controller';
import UserNotificationsController from './controllers/user_notifications_controller';

window.Stimulus = Application.start()
Stimulus.register("submittable", SubmittableController)
Stimulus.register("user-notifications", UserNotificationsController)
